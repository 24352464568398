import { memo, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Draggable from "react-draggable";
import TemplateThumbanails from "../TemplateThumbanails/TemplateThumbanails";
import { findTheThumbPositions, ratioCalculte, findTheThumbContPositions } from "../../Utils/Utils";
import * as constant from "../../CONSTANT";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SliderThumb.scss";
import { useSelector } from "react-redux";
import ContentCard from "../ContentCard/ContentCard";

const SliderThumb = ({
  newsPages,
  dropdownCategoryValues,
  dropDownChangeFromNewsPaperGrid,
  thumbNailAds,
  thumbNailsContent,
  userClickDone,
  functForUserClick,
  regionSlide,
}) => {
  const settingsThumbs = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
  };

  const sliderRef = useRef();
  const refDrags = useRef(null);
  const [smTemplate, setSmTemplate] = useState([]);
  const { fetchMetaDataInitalDataParams } = useSelector(
    (state) => state.fetchinitialmetadata
  );
  const { data} = useSelector((state) => state.data);
  // console.log('slider Thumb fetchMetaDataInitalDataParams : ', fetchMetaDataInitalDataParams)
  const selectedDate = dropdownCategoryValues?.publish_date; // Assuming dropdownCategoryValues contains publish_date
  

  const seletActionOfThumbNail = (e, thumbPageVal, thumbTemplateValue, contentDataForPage) => {
    const pageNdTemplate = {
      page: thumbPageVal,
      template: thumbTemplateValue,
      content: contentDataForPage,
    };
    dropDownChangeFromNewsPaperGrid(pageNdTemplate); //callBackToThe Parent module to updatete the existing Template dropdown
    functForUserClick();
  };

  const moveSlider = (pageValue) => {
    //assignCondn👇if the match happens
    sliderRef.current.slickGoTo(pageValue - 1, false);
  };
  // console.log('dropdownCategoryValues', dropdownCategoryValues)
  useEffect(() => {
    if (dropdownCategoryValues?.page?.value) {
      const pageVal = dropdownCategoryValues?.page;
      const dragThumbnailsData = thumbNailAds?.filter((item) => {
        return item?.page === pageVal?.value;
      });
      const pageAdsItem = dragThumbnailsData?.find((ad) => ad?.page);
      const smTemplateFind = smTemplate?.find(
        (s) => s?.value === pageAdsItem?.adv_template_id
      );

    
      const pageNdTemplate = {
        page: pageVal,
        template: smTemplateFind,
      };
      dropDownChangeFromNewsPaperGrid(pageNdTemplate);
      moveSlider(dropdownCategoryValues?.page?.value);
    }
  }, [
    dropdownCategoryValues?.page?.value,
    dropDownChangeFromNewsPaperGrid,
    dropdownCategoryValues?.page,
    thumbNailAds,
    smTemplate,
  ]);

  useEffect(() => {
    if (dropdownCategoryValues?.page?.value) {
      const pageVal = dropdownCategoryValues?.page;
      const contentDataForPage = thumbNailsContent?.filter((item) => {
        return item?.page === pageVal?.value;
      });
      const pageAdsItem = contentDataForPage?.find((ad) => ad?.page);
      const smTemplateFind = smTemplate?.find(
        (s) => s?.value === pageAdsItem?.adv_template_id
      );
      const pageNdTemplate = {
        page: pageVal,
        template: smTemplateFind,
      };
      dropDownChangeFromNewsPaperGrid(pageNdTemplate);
      moveSlider(dropdownCategoryValues?.page?.value);
    }
  }, [
    dropdownCategoryValues?.page?.value,
    dropDownChangeFromNewsPaperGrid,
    dropdownCategoryValues?.page,
    thumbNailsContent,
    smTemplate,
  ]);

// console.log('thumbNailsContent thumbNailsContent........', thumbNailsContent)
  useEffect(() => {
    const optionTemplateTransform = fetchMetaDataInitalDataParams?.[0]?.[
      "adv_template"
    ]?.map((el) => ({
      ...el,
      label: el?.template_name,
      value: el?.template_id,
    }));
    setSmTemplate(optionTemplateTransform);
  }, [fetchMetaDataInitalDataParams]);

  return (
    <div className="newsSlider containerThumb">
      <Slider {...settingsThumbs} ref={sliderRef}>
        {newsPages?.map((pageItem) => {
          const dragThumbnailsData = thumbNailAds?.filter((item) => {
            return item?.page === pageItem?.value;
          });
          const pageAdsItem = dragThumbnailsData?.find((ad) => ad?.page);
          const smTemplateFind = smTemplate?.find(
            (s) => s?.value === pageAdsItem?.adv_template_id
          );
          
          // Filter Contentdata for the current page
          // const contentDataForPage = data?.filter(
          //   (item) => item?.page_no === pageItem?.value
          // );

          const contentDataForPage = thumbNailsContent?.filter(
            (item) =>
              item?.page_no === pageItem?.value &&
              item?.publish_date === selectedDate // Filter by page and publish_date
          );


          // console.log('Contentdata for page:', pageItem?.value, Contentdata);
          
          return (
            <div className="thumbOuter" key={pageItem?.value}>
              <div
                className={`thumbnail ${
                  userClickDone &&
                  pageItem?.value === dropdownCategoryValues?.page?.value
                    ? `selectedThumb`
                    : ``
                }`}
                onClick={(e) => {
                  seletActionOfThumbNail(
                    e,
                    pageItem,
                    smTemplateFind,
                    dragThumbnailsData,
                    contentDataForPage
                  );
                }}
                style={{
                  width: constant.THUMBNAIL_SLIDER_WIDTH + "px",
                  height: constant.THUMBNAIL_SLIDER_HEIGHT + "px",
                }}
              >
                <div>
                  <TemplateThumbanails
                    propTemplate={
                      smTemplateFind ??
                      smTemplate?.find((option) => option?.template_default_all)
                    }
                    adsRef={pageAdsItem}
                    location={regionSlide}
                    page_No={pageAdsItem?.page ?? pageItem?.value}
                  />
                </div>

                {/* {console.log('contentDataForPage..........', contentDataForPage)} */}
                {/* {contentDataForPage?.map((contentItem) => (
                  <ContentCard key={contentItem?.id} content={contentItem} />
                ))} */}
              <div className="contentPosn">
                {contentDataForPage?.map((contentItem, index) => {
                  const thumbGrid = findTheThumbPositions(
                    contentItem?.content_position_x,
                    contentItem?.content_position_y
                  );
                  return (
                    <Draggable
                      bounds="parent"
                      position={{
                        x: thumbGrid?.newX,
                        y: thumbGrid?.newY,
                      }}
                      defaultPosition={{
                        x: thumbGrid?.newX,
                        y: thumbGrid?.newY,
                      }}
                      disabled
                      key={`${contentItem?.content_position_x}-${contentItem?.content_position_y}-${index}`} // Use a unique key
                      nodeRef={refDrags}
                    >
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: "#28a745",
                          border: "1px solid #78b7d3",
                          width: `${ratioCalculte(
                            contentItem?.content_width,
                            constant.RATIO_THUMBNAIL
                          )}px`,
                          height: `${ratioCalculte(
                            contentItem?.content_height,
                            constant.RATIO_THUMBNAIL
                          )}px`,
                        }}
                        ref={refDrags}
                      >
                        <div className="mx-auto text-center">{contentItem?.publish_details[0]?.editorial_id}</div>
                      </div>
                    </Draggable>
                  );
                })}
              </div>
 
                <div className="dragsPosn">
                  {dragThumbnailsData?.map((ls, i) => {
                    const thumbGrid = findTheThumbPositions(
                      ls?.gridX,
                      ls?.gridY - 170
                    );
                    return (
                      <Draggable
                        bounds="parent"
                        position={{
                          x: thumbGrid?.newX,
                          y: thumbGrid?.newY,
                        }}
                        defaultPosition={{
                          x: thumbGrid?.newX,
                          y: thumbGrid?.newY,
                        }}
                        disabled
                        key={ls?.gridX + ls?.gridY + i}
                        nodeRef={refDrags}
                      >
                        <div
                          style={{
                            position: "absolute",
                            backgroundColor: "#cdebf9",
                            border: "1px solid #78b7d3",
                            width: `${ratioCalculte(
                              ls?.width,
                              constant.RATIO_THUMBNAIL
                            )}px`,
                            height: `${ratioCalculte(
                              ls?.height,
                              constant.RATIO_THUMBNAIL
                            )}px`,
                          }}
                          ref={refDrags}
                        >
                          <div className="mx-auto text-center">{ls?.id}</div>
                        </div>
                      </Draggable>
                    );
                  })}
                </div>
              </div>
              <span>{pageItem?.label}</span>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default memo(SliderThumb);
