export const FETCH_ADLISTING_DATA  = 'FETCH_ADLISTING_DATA';

export const DELETE_ONE_ADLISTING_DATA = 'DELETE_ONE_ADLISTING_DATA'; 

export const FETCH_INITIAL_PARAMS_DATA = 'FETCH_INITIAL_PARAMS_DATA';
export const FETCH_INITIAL_PARAMS_DATA_SUCESS = 'FETCH_INITIAL_PARAMS_DATA_SUCESS';
export const FETCH_INITIAL_PARAMS_DATA_FAILED = 'FETCH_INITIAL_PARAMS_DATA_FAILED';

export const FETCH_REGIONS= 'FETCH_REGIONS';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS';
export const FETCH_REGIONS_FAILED = 'FETCH_REGIONS_FAILED';

export const FETCH_ADS_BY_POST = 'FETCH_ADS_BY_POST';
export const FETCH_ADS_BY_POST_SUCCESS = 'FETCH_ADS_BY_POST_SUCCESS';
export const FETCH_ADS_BY_POST_FAILED = 'FETCH_ADS_BY_POST_FAILED';

export const DEL_ONE_ADLIST_ADD_TO_AD_POSITION = 'DEL_ONE_ADLIST_ADD_TO_AD_POSITION'; 
export const DEL_AD_POSITION_ADD_TO_ADLIST = 'DEL_AD_POSITION_ADD_TO_ADLIST';


export const PRIVATE_DB_SEND_COPY_FROM_FETCHED_POST = 'PRIVATE_DB_SEND_COPY_FROM_FETCHED_POST';
export const PRIVATE_DB_MOVE_ADLIST_TO_AD_POSITION = 'PRIVATE_DB_MOVE_ADLIST_TO_AD_POSITION';
export const PRIVATE_DB_MOVE_AD_POSITION_TO_ADLIST = 'PRIVATE_DB_MOVE_AD_POSITION_TO_ADLIST'; 

export const PRIVATE_DB_SUBMIT_ADS_ALL_TYPES = 'PRIVATE_DB_SUBMIT_ADS_ALL_TYPES'; 
export const PRIVATE_DB_SUBMIT_ADS_ALL_TYPES_SUCCESS = 'PRIVATE_DB_SUBMIT_ADS_ALL_TYPES_SUCCESS';
export const PRIVATE_DB_SUBMIT_ADS_ALL_TYPES_FAILED = 'PRIVATE_DB_SUBMIT_ADS_ALL_TYPES_FAILED'; 
export const PRIVATE_DB_SUBMIT_SUCCESS_ERROR_CLEANUP = 'PRIVATE_DB_SUBMIT_SUCCESS_ERROR_CLEANUP'; 

export const MOVE_AD_FROM_TABLE_TO_AD_POSITIONER = 'MOVE_AD_FROM_TABLE_TO_AD_POSITIONER';
export const MOVE_AD_FROM_FILTERED_TO_AD_POSITIONER = 'MOVE_AD_FROM_FILTERED_TO_AD_POSITIONER';
export const REMOVE_A_AD_FROM_POSITIONER_SECTION = 'REMOVE_A_AD_FROM_POSITIONER_SECTION';
export const SUBMITE_DB_POSITION = 'SUBMITE_DB_POSITION';
export const SUBMITE_DB_POSITION_SUCCESS = 'SUBMITE_DB_POSITION_SUCCESS';
export const SUBMITE_DB_POSITION_FAILED = 'SUBMITE_DB_POSITION_FAILED';
export const SUBMITE_DB_CLEANUP = 'SUBMITE_DB_CLEANUP';

export const MOVE_AD_POSITIONER_TO_TABLE = 'MOVE_AD_POSITIONER_TO_TABLE';
export const MOVE_AD_POSITIONER_TO_FILTERED = 'MOVE_AD_POSITIONER_TO_FILTERED';

export const EDIT_SLUG_NO_AD = 'EDIT_SLUG_NO_AD';
export const EDIT_SLUG_NO_AD_SUCCESS = 'EDIT_SLUG_NO_AD_SUCCESS';
export const EDIT_SLUG_NO_AD_FAILED =  'EDIT_SLUG_NO_AD_FAILED';
export const EDIT_SLUG_NO_AD_CLEANUP = 'EDIT_SLUG_NO_AD_CLEANUP';



export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const FETCH_DATA_SUCCESS = 'FETCH_DATA_SUCCESS';
export const FETCH_DATA_FAILURE = 'FETCH_DATA_FAILURE';

// export const POST_EDITORIAL_DETAILS_REQUEST = "POST_EDITORIAL_DETAILS_REQUEST";
export const POST_EDITORIAL_DETAILS_REQUEST = "POST_EDITORIAL_DETAILS_REQUEST";
export const POST_EDITORIAL_DETAILS_SUCCESS = "POST_EDITORIAL_DETAILS_SUCCESS";
export const POST_EDITORIAL_DETAILS_FAILURE = "POST_EDITORIAL_DETAILS_FAILURE";

export const REMOVE_CONTENT_DETAILS = 'REMOVE_CONTENT_DETAILS';

