import * as type from "../Constants/adlist";
import * as utilFunct from "../../Utils/Utils";
import * as constantVal from "../../CONSTANT";

const initailState = {
  pageHavingHeader: "", //withHeader-1 or else anything
  pageSize: {
    width: "",
    height: "",
  },
  adListedWithoutGrid: [], //adList
  adPositionedWithGrid: [], //adPositioned////➡️METASTATE
  //finalSubmiting
  adsMixinDataTransformedForPost: [], ///➡️onlyBACKUP_DATA_records which are sumited to backend
  submitAllAdsLoading: false,
  submitAllAdsSuccess: null,
  submitAllAdsError: null,
};


const PAPER_WIDTH = constantVal.NEWS_PAPER_WIDTH;
const PAPER_HEIGHT = constantVal.NEWS_PAPER_HEIGHT;

console.log('PAPER_WIDTH' , PAPER_WIDTH)
console.log('PAPER_HEIGHT' , PAPER_HEIGHT)
const appendPageCompatibilty = (adItem) => {
  //condin can be added in future in case if the ads to plcaexd in respective fiels page
  return 1;
};

// const appendRequiredFieldsForModule = (filteredArray) => {
//   //transformBackednd array to give componenets requirement
//   const returnTransformedArray = filteredArray?.map((el) => {
//     const [widthPx, heightPx] = utilFunct.getAdsSize(
//       el?.size,
//       constantVal.RATION_MAINNEWSGRID
//     );
//     const height = +heightPx; //👈SizeHeight
//     const width = +widthPx; //👈SizeWidth
//     const name = el?.position_name;
//     const gridX = el?.dummy_chart_position_x
//       ? Number(el?.dummy_chart_position_x)
//       // : 470;
//       : PAPER_WIDTH - width ;
//     const gridY = el?.dummy_chart_position_y
//       ? Number(el?.dummy_chart_position_y)
//       // : 0;
//       :PAPER_HEIGHT - (PAPER_HEIGHT - height);
//     const appendRequiredFieldForComponenets = {
//       height,
//       width,
//       name,
//       gridX,
//       gridY,
//     };

//     const page = el?.page ? el?.page : appendPageCompatibilty(el);
//     const _new_publish_date = el?.publish_date;
//     const element = {
//       ...el,
//       ...appendRequiredFieldForComponenets,
//       page,
//       _new_publish_date,
//     };
//     return element;
//   });

  
//   return returnTransformedArray;
// };


// const appendRequiredFieldsForModule = (filteredArray) => {
//   // Transform backend array to match component requirements
//   const returnTransformedArray = filteredArray?.map((el) => {
//     const [widthPx, heightPx] = utilFunct.getAdsSize(
//       el?.size,
//       constantVal.RATION_MAINNEWSGRID
//     );
//     const height = +heightPx; //👈 SizeHeight
//     const width = +widthPx; //👈 SizeWidth
//     const name = el?.position_name;
//     const gridX = el?.dummy_chart_position_x
//       ? Number(el?.dummy_chart_position_x)
//       : 470;
//     const gridY = el?.dummy_chart_position_y
//       ? Number(el?.dummy_chart_position_y)
//       : 0;
//     const appendRequiredFieldForComponenets = {
//       height,
//       width,
//       name,
//       gridX,
//       gridY,
//     };

//     const page = el?.page ? el?.page : appendPageCompatibilty(el);
//     const _new_publish_date = el?.publish_date;
//     const element = {
//       ...el,
//       ...appendRequiredFieldForComponenets,
//       page,
//       _new_publish_date,
//     };
//     return element;
//   });

//   // Filter the ads where gridX == 470 and gridY == 0
//   const adsWithGridX470AndY0 = returnTransformedArray.filter(
//     (ad) => ad.gridX === 470 && ad.gridY === 0
//   );

//   // Sort these ads by size (larger size = more area)
//   const sortedAds = adsWithGridX470AndY0.sort(
//     (a, b) => (b.width * b.height) - (a.width * a.height)
//   );

//   // Get the largest and second largest ads
//   const firstLargestAd = sortedAds[0];
//   const secondLargestAd = sortedAds[1];

//   // Log the largest and second largest ads
//   console.log("Largest Ad:", firstLargestAd);
//   console.log("Second Largest Ad:", secondLargestAd);

//   // Update the positioning of the largest and second largest ads
//   const updatedAds = returnTransformedArray.map((ad) => {
//     // If the ad is the largest one, position it at the bottom right
//     if (ad === firstLargestAd) {
//       return {
//         ...ad,
//         gridX: PAPER_WIDTH - ad.width - 3,  // Position at the bottom right (adjust according to your layout)
//         gridY: PAPER_HEIGHT - ad.height - 58.5, // Position at the bottom (adjust accordingly)
//       };
//     }
    
//     // If the ad is the second largest one, position it above the largest one
//     if (ad === secondLargestAd) {
//       return {
//         ...ad,
//         gridX: PAPER_WIDTH - ad.width,  // Keep the same horizontal position as the largest ad
//         // gridY: PAPER_HEIGHT - firstLargestAd.height - ad.height - 58.5, // Position it above the largest ad dynamically
//         gridX: PAPER_HEIGHT - firstLargestAd.height - ad.height - 58.5,
//       };
//     }

//     // For all other ads, keep their original positioning
//     return ad;
//   });

//   // Return the filtered and updated ads
//   return updatedAds;
// };




const appendRequiredFieldsForModule = (filteredArray) => {
  const offsetY = 5;  // Offset to move the second largest ad up
  const offsetX = 5;  // Offset to move the largest ad to the right

  // Group ads by their page
  const adsByPage = filteredArray.reduce((acc, el) => {
    const page = el?.page || appendPageCompatibilty(el);  // Use existing page or fallback to the default logic
    if (!acc[page]) acc[page] = [];
    acc[page].push(el);
    return acc;
  }, {});

  // Iterate through each page group and transform ads for each page
  const returnTransformedArray = Object.values(adsByPage).flatMap((pageAds) => {
    // Sort the ads on this page by their size (width * height) in descending order
    const sortedAds = pageAds.map((el) => {
      const [widthPx, heightPx] = utilFunct.getAdsSize(el?.size, constantVal.RATION_MAINNEWSGRID);
      const width = +widthPx;
      const height = +heightPx;
      return {
        ...el,
        width,
        height,
        area: width * height, // Calculate area for sorting
      };
    }).sort((a, b) => b.area - a.area);  // Sort in descending order by area

    // Get the largest and second largest ads for this page
    const largestAd = sortedAds[0];  // The largest ad
    const secondLargestAd = sortedAds[1];  // The second largest ad

    // Transform the ads and position them for this page
    return sortedAds.map((el) => {
      const [widthPx, heightPx] = utilFunct.getAdsSize(el?.size, constantVal.RATION_MAINNEWSGRID);
      const height = +heightPx;
      const width = +widthPx;
      const name = el?.position_name;

      let gridX = el?.dummy_chart_position_x ? Number(el?.dummy_chart_position_x) : 0;
      let gridY = el?.dummy_chart_position_y ? Number(el?.dummy_chart_position_y) : 0;

      if (gridX === 0 && gridY === 0) {
        // If the ad has no specific position (gridX == 0 and gridY == 0), apply the special logic for positioning

        if (el === largestAd) {
          gridX = PAPER_WIDTH - width - offsetX;  // Align to the right with an offset
          gridY = PAPER_HEIGHT - height - offsetY;  // Align to the bottom with an offset

        // Position the second largest ad above the largest ad
        } else if (el === secondLargestAd) {
          gridX = PAPER_WIDTH - width - offsetX;  // Align to the right (same as the largest ad)

          // Calculate gridY for the second largest ad to be placed above the largest ad
          const [largestWidthPx, largestHeightPx] = utilFunct.getAdsSize(largestAd?.size, constantVal.RATION_MAINNEWSGRID);
          gridY = PAPER_HEIGHT - largestHeightPx - height - (offsetY * 2);  // Adjust Y to place above the largest ad
        }
      }

      // Construct the transformed element with additional required fields
      const appendRequiredFieldForComponenets = {
        height,
        width,
        name,
        gridX,
        gridY,
      };

      const page = el?.page ? el?.page : appendPageCompatibilty(el);
      const _new_publish_date = el?.publish_date;
      
      return {
        ...el,
        ...appendRequiredFieldForComponenets,
        page,
        _new_publish_date,
      };
    });
  });

  return returnTransformedArray;
};


// contentcard //

const appendContentFieldsForModule = (contentArray) => {
  const offsetY = 5;
  const offsetX = 5;

  // Group content by page_no
  const contentByPage = contentArray.reduce((acc, el) => {
    const page = el?.page_no || 1; // Default to page 1 if page_no is missing
    if (!acc[page]) acc[page] = [];
    acc[page].push(el);
    return acc;
  }, {});

  // Transform content within each page
  const transformedContentArray = Object.values(contentByPage).flatMap((pageContent) => {
    const sortedContent = pageContent.map((el) => {
      const width = el?.width || 100;
      const height = el?.height || 50;
      return {
        ...el,
        width,
        height,
        area: width * height,
      };
    }).sort((a, b) => b.area - a.area); // Sort by area descending

    // Apply positioning logic
    return sortedContent.map((el, index) => {
      const gridX = el?.gridX || (index * (el.width + offsetX));
      const gridY = el?.gridY || (index * (el.height + offsetY));
      return {
        ...el,
        gridX,
        gridY,
      };
    });
  });

  return transformedContentArray;
};
const groupContentByPage = (contentList) => {
  return contentList.reduce((acc, content) => {
    const page = content.page_no || 1; // Default to page 1 if page_no is missing
    if (!acc[page]) {
      acc[page] = [];
    }
    acc[page].push(content);
    return acc;
  }, {});
};



export default function dbpostadscombined(state = initailState, action) {
  switch (action?.type) {
    case type.MOVE_AD_FROM_TABLE_TO_AD_POSITIONER:
      //incase aded oneTableItem and again fetching filter happens means, if it one same page of position it should concat or else replace
      const pageDecider = action?.payload?.find((el) =>
        el?.page_name?.includes("1")
      );
      const samePage = state?.adPositionedWithGrid?.find((el) => {
        const findMatch =
          action?.payload?.find((tab) => tab?.page_id === el?.page_id) &&
          action?.payload?.find(
            (tab) => tab?.publish_date === el?.publish_date
          );
        return findMatch;
      });
      return {
        ...state,
        adPositionedWithGrid: samePage
          ? state?.adPositionedWithGrid?.concat(
              appendRequiredFieldsForModule(action?.payload)
            )
          : appendRequiredFieldsForModule(action?.payload),
        pageHavingHeader: pageDecider ? true : false,
      };

    case type.MOVE_AD_FROM_FILTERED_TO_AD_POSITIONER:
      const page_Decider = action?.payload?.find((el) =>
        el?.page_name?.includes("1")
      );

      const apiRes = action?.fetchedResults;
      //in case if in same page ads, only one ads having a template but other don't have at that time of fetch filterParams ads theer is one template mismatch issue will happen.. hence 👇
      // const oneOftheAdsHasTemplate = apiRes.find(ad => ad?.ad_template);
      // const transformedPayload = oneOftheAdsHasTemplate ?  apiRes.map(el=>({...el, ad_template: oneOftheAdsHasTemplate?.ad_template})) : apiRes

      return {
        ...state,
        adPositionedWithGrid: appendRequiredFieldsForModule(apiRes),
        pageHavingHeader: page_Decider ? true : false,
      };

    case type.REMOVE_A_AD_FROM_POSITIONER_SECTION:
      const findIndex = action?.payload?.updatingValues?.findIndex(
        (el) => el?.id === action?.payload?.deletingId
      );
      const updatedListGrid = [...action?.payload?.updatingValues];
      updatedListGrid.splice(findIndex, 1);
      const updatePageDecider = updatedListGrid?.find((el) =>
        el?.page_name?.includes("1")
      );
      // console.log('updatedListGrid', updatedListGrid, action?.payload)
      // console.log('action?.payload?.updatingValues', action?.payload?.updatingValues)

      return {
        ...state,
        adPositionedWithGrid: updatedListGrid,
        pageHavingHeader: updatePageDecider ? true : false,
      };

    //
    case type.SUBMITE_DB_POSITION:
      return {
        ...state,
        submitAllAdsLoading: true,
        adsMixinDataTransformedForPost: action?.payload,
      };
    case type.SUBMITE_DB_POSITION_SUCCESS:
      return {
        ...state,
        submitAllAdsLoading: false,
        submitAllAdsSuccess: action?.successInSubmit,
        submitAllAdsError: null,
      };
    case type.SUBMITE_DB_POSITION_FAILED:
      return {
        ...state,
        submitAllAdsLoading: false,
        submitAllAdsSuccess: null,
        submitAllAdsError: action?.errorInSubmitting,
      };

    case type.SUBMITE_DB_CLEANUP:
      return {
        ...state,
        submitAllAdsLoading: false,
        submitAllAdsSuccess: null,
        submitAllAdsError: null,
        adPositionedWithGrid: [],
        adsMixinDataTransformedForPost: [],
      };

    default:
      return state;
  }
}
