import * as type from "../Constants/adlist";

const initialState = {
  deleteContentLoading: false,
  deleteContentSuccess: null,
  deleteContentError: null,
  contentList: [], // Holds the current list of content cards
};

export default function deleteContentReducer(state = initialState, action) {
  switch (action.type) {
    // Action: Trigger content deletion
    case type.REMOVE_CONTENT_DETAILS:
      return {
        ...state,
        deleteContentLoading: true,
        deleteContentSuccess: null,
        deleteContentError: null,
      };

    // Action: Content deletion success
    case "REMOVE_CONTENT_DETAILS_SUCCESS":
      return {
        ...state,
        deleteContentLoading: false,
        contentList: state.contentList.filter(
          (item) => item !== action.payload
        ), // Remove the content card from the list
        deleteContentSuccess: "Content deleted successfully",
        deleteContentError: null,
      };

    // Action: Content deletion failed
    case "REMOVE_CONTENT_DETAILS_FAILED":
      return {
        ...state,
        deleteContentLoading: false,
        deleteContentSuccess: null,
        deleteContentError: action.error,
      };

    // Default case
    default:
      return state;
  }
}
