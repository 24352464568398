import { call, put, takeEvery } from 'redux-saga/effects';
import httpService from '../httpService';
import * as apiUrl from '../apiUrl';
import * as actions from '../../ReduxState/Actions/actionAd';  // One level up

// API call to fetch data, now including region_id and publish_date
async function fetchDataFromApi(region_id, publish_date) { 
  try {
   
    const response = await httpService.getCallMethod(apiUrl.editorialDetails+'/'+region_id+'/'+publish_date);
    console.log('Fetched Data:', response);

  const fullUrl = `${apiUrl.editorialDetails}/${region_id}/${publish_date}`;

  console.log('Request URL:', fullUrl);


    return response;
  } catch (error) {
    throw error;   
  }
}

function* fetchDataSaga(action) {
  try {
   
    const { region_id, publish_date } = action.payload;

    const data = yield call(fetchDataFromApi, region_id, publish_date);  
    console.warn('Fetched Data in Saga:', data);  // Debugging: Log data
    yield put(actions.fetchDataSuccess(data));  // Dispatch success action
  } catch (error) {
    console.error('Error in saga:', error);   
    yield put(actions.fetchDataFailure(error.message));   
  }
}

function posteditoraldata(action){
  
}



function* watchFetchData() {
  yield takeEvery('FETCH_DATA_REQUEST', fetchDataSaga);  // Change to the correct action type
}

export default watchFetchData;