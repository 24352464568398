import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ImInfo } from "react-icons/im";
import './TooltipAd.scss';



const TooltipAd = ({ renderComponent }) => {
  const renderTooltip = (props) => {
    return <Tooltip {...props} className="custom-tooltip">{renderComponent}</Tooltip>;
  };

  return (
    <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <span className="d-flex flex-column align-items-center">
        <ImInfo size={30}/>
        <span className="mt-2">Ad info</span>
      </span>
    </OverlayTrigger>
  );
};

export default TooltipAd;
